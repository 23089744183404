<template>
  <div class="">
    <div class="gg-container">
      <div class="search-container">
        <div class="search-container-fn-input">
          <el-input
            style="width: 200px"
            size="small"
            placeholder="关键字搜索"
            prefix-icon="el-icon-search"
            v-model="searchParams.symptom_name"
            clearable
            @change="getList('rest')"
          >
          </el-input>
        </div>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
					@click="getList('restPage')"
				>
					<span>查找</span>
				</el-button>
        <div
          class="search-container-fn-input"
          style="margin-left: auto"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="success"
            icon="el-icon-plus"
            style="padding: 7px 8px;margin-left: 5px;"
            @click="editItem('add')"
          >
            <span style="font-size: 12px">新增</span>
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="delItemBatch(multipleSelectionPres)"
            v-if="multipleSelectionPres.length >= 1"
            >批量删除
          </el-button>
        </div>
        <!-- <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="delItem(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div> -->
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="editItem('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
      </div>

      <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="725"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index:0"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" align="center" width="50">
        </el-table-column>
        <template v-for="(column, index) in tableHead">
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="editArticle('view', scope.row)"
              >
                {{ scope.row[column.column_prop] }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="
              column.field_type === 'select' && column.visible === true
            "
            show-overflow-tooltip
          >
            <template #header>
              <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                  searchTableHead.filter(
                    (item) => item.name == column.column_prop
                  ).length > 0
                "
              >
                <span slot="reference" class="search-header">
                  <span class="search-title">{{
                    column.column_label_user_definition
                      ? column.column_label_user_definition
                      : column.column_label
                  }}</span>
                  <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                      isSearch: searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                      'el-icon-zoom-in': searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                    }"
                  />
                </span>
                <el-select
                  v-if="'is_recommend' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.is_recommend)
                  "
                  v-model="searchParams.is_recommend"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in is_recommend_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-popover>
              <span v-else>{{
                column.column_label_user_definition
                  ? column.column_label_user_definition
                  : column.column_label
              }}</span>
            </template>
            <template slot-scope="scope">
              <el-switch
                v-if="column.column_prop == 'status'"
                class="switch"
                @change="changeStatus($event, scope.row)"
                v-model="scope.row.status"
                active-text="启用"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#C8C8C8"
              >
              </el-switch>
              <el-switch
                v-if="column.column_prop == 'is_recommend'"
                class="switch"
                @change="changeStatus1($event, scope.row)"
                v-model="scope.row.is_recommend"
                active-text="启用"
                :active-value="1"
                :inactive-value="0"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#C8C8C8"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a v-if="column.column_prop == 'is_recommend'">
                {{ scope.row.is_recommend == 0 ? "否" : "是" }}
              </a>
              <a v-else>{{ scope.row[column.column_prop] }}</a>
            </template>
            <template #header>
              <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                  searchTableHead.filter(
                    (item) => item.name == column.column_prop
                  ).length > 0
                "
              >
                <span slot="reference" class="search-header">
                  <span class="search-title">{{
                    column.column_label_user_definition
                      ? column.column_label_user_definition
                      : column.column_label
                  }}</span>
                  <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                      isSearch: searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                      'el-icon-zoom-in': searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                    }"
                  />
                </span>
                <el-select
                  v-if="'group_name' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.group_name)
                  "
                  v-model="searchParams.group_id"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'trigger' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.trigger)
                  "
                  v-model="searchParams.trigger"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'from_type' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.from_type)
                  "
                  v-model="searchParams.from_type"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in from_type_List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'client' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.client)
                  "
                  v-model="searchParams.client_type"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in clientList"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-popover>
              <span v-else>{{
                column.column_label_user_definition
                  ? column.column_label_user_definition
                  : column.column_label
              }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>

    <!--新增编辑 快捷回复-->
    <el-dialog
      :title="dialogTitle + '症状'"
      :visible.sync="dialogVisible"
      width="60%"
      v-if="dialogVisible"
    >
      <el-form
        ref="ruleForm"
        :model="currInfo"
        label-width="80px"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="所属科室" prop="depart_ids">
          <template v-if="openType == 'add'">
            <el-cascader
              v-model="currInfo.depart_ids"
              :options="departOptions"
              :props="{
                checkStrictly: true,
                value: 'id',
                label: 'depart_name',
                children: 'child',
              }"
              clearable
            ></el-cascader>
          </template>
          <template v-else>
            {{ getDepartName(currInfo.depart_ids) }}
          </template>

          <!-- <el-select v-model="currInfo.depart_id"
										 clearable
										 placeholder="请选择"
					>
							<el-option
											v-for="(val,key) in departDic"
											:key="key"
											:label="val"
											:value="key"
							>
							</el-option>
					</el-select> -->
        </el-form-item>
        <el-form-item label="病症名称" prop="name">
          <el-input v-model="currInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="病症编码" prop="code">
          <el-input v-model="currInfo.code"></el-input>
        </el-form-item>

        <el-form-item label="病症排序" prop="sort">
          <el-input v-model="currInfo.sort"></el-input>
        </el-form-item>

        <el-form-item label="热搜" prop="is_recommend">
          <el-radio
            v-for="item in isOptions"
            v-model="currInfo.is_recommend"
            v-bind:key="item.value"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </el-form-item>

        <el-form-item label="置顶" prop="is_top">
          <el-radio
            v-for="item in isOptions"
            v-model="currInfo.is_top"
            v-bind:key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio
            v-for="item in statusOptions"
            v-model="currInfo.status"
            v-bind:key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="small" @click="saveForm('ruleForm')"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import {
  getHospitalSymptomList,
  delHospitalSymptom,
  departList,
  symptomStatus,
  getHotSearchStatus
} from "@/api/basic/index";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import request from "@/utils/request";
import { mapState } from "vuex";

export default {
  name: "disease",
  components: {
    Pagination,
    editTableHead,
  },

  computed: {
    getDepartName() {
      return (value) => {
        let depart = this.departOptions.find((v) => v.id == value[0]);
        let departName = depart.depart_name;
        if (value.length == 2) {
          let twoDepart = depart.child.find((v) => v.id == value[1]);
          let two_depart_name = twoDepart ? twoDepart.depart_name : "";
          departName = two_depart_name
            ? departName + "/" + twoDepart.depart_name
            : departName;
        }
        return departName;
      };
    },
    dialogTitle() {
      return (
        {
          add: "添加",
          edit: "修改",
        }[this.openType] || "添加"
      );
    },
    ...mapState({
      departDic: (state) => state.dictionary.departDic,
      table_options: (state) => state.user.table_options,
    }),
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    this.$store.dispatch("dictionary/getDicData", [
      { stateName: "departDic", api: "/admin/setting/getDepartSelect" },
    ]);
  },
  async created() {
    await this._getAdminFieldIndex();
    this.departList();
    this.getList();
  },
  data() {
    return {
      table_type:'disease',
      is_recommend_list: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "appraise_content",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
        {
          name: "is_recommend",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      pageTitle: "病症管理",
      departOptions: [],
      searchParams: {
        depart_name: "",
      },
      isOptions: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      statusOptions: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],
      currInfo: {
        name: "",
        code: null,
        sort: null,
        depart_id: null,
        is_recommend: null,
        status: true,
        is_top: null,
      },
      dialogVisible: false,
      replyContent: "",
      openType: "",
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      ruleForm: {
        depart_id: "",
        name: "",
        code: "",
        sort: "",
      },
      rules: {
        depart_ids: [
          { required: true, message: "请选择科室", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入症状名称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入症状编码", trigger: "blur" }],
        sort: [
          { required: true, message: "请输入科室排序", trigger: "change" },
        ],
        is_recommend: [
          { required: true, message: "请选择热搜", trigger: "change" },
        ],
        is_top: [{ required: true, message: "请选择置顶", trigger: "change" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
    };
  },

  methods: {
    delItemBatch(e) {
      console.log(e, 2222);
      let ids = [];
      e.forEach((item) => {
        ids.push(item.id);
      });
      this.$confirm(`是否删除该病状？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          delHospitalSymptom({ ids: ids })
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
      console.log(ids);
    },
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "disease",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.disease;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getHospitalSymptomList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    changeStatus(e, row) {
      symptomStatus(row.id, e)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已启用" : "已禁用";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(e);
    },
    changeStatus1(e, row) {
      let data= {
        is_recommend: e,
        id: row.id,
      }
      getHotSearchStatus(data)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已启用" : "已禁用";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(e);
    },
    departList() {
      departList().then((res) => {
        if (res.code == 200) {
          this.departOptions = res.data;
        }
      });
    },
    editItem(type, item) {
      this.openType = type;
      this.currInfo = {
        name: "",
        code: null,
        sort: null,
        depart_id: null,
        is_recommend: null,
        status: null,
        is_top: null,
      };
      if (type === "add") {
        console.log(item);
      } else {
        if (!item) {
          this.$message({
            type: "error",
            message: "请选择要修改的症状",
          });
          return;
        }
        this.currInfo.name = item.symptom_name;
        this.currInfo.code = item.symptom_code;
        this.currInfo.depart_id = item.depart_id + "";
        this.currInfo.is_recommend = item.is_recommend;
        this.currInfo.sort = item.sort;
        this.currInfo.id = item.id;
        this.currInfo.depart_ids = item.depart_ids;
        this.currInfo.status = item.status;
        this.currInfo.is_top = item.is_top;
      }
      this.dialogVisible = true;
    },
    delItem(item) {
      let params = {};
      params.id = item.id;
      this.$confirm(`是否删除症状： "${item.symptom_name}"？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          delHospitalSymptom(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    saveForm(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return;
        } else {
          let params = {};
          let url = "";
          params.depart_id = this.currInfo.depart_id;
          params.is_recommend = this.currInfo.is_recommend;
          params.symptom_name = this.currInfo.name;
          params.symptom_code = this.currInfo.code;
          params.sort = this.currInfo.sort;
          params.depart_id = this.currInfo.depart_ids[0];
          params.is_top = this.currInfo.is_top;
          params.status = this.currInfo.status;
          if (this.currInfo.depart_ids.length == 2) {
            params.two_depart_id = this.currInfo.depart_ids[1];
          }

          if (this.openType == "add") {
            url = "/admin/hospital/addHospitalSymptom"; //添加
          } else {
            //编辑
            url = "/admin/hospital/editHospitalSymptom/" + this.currInfo.id;
          }
          request({
            url: url,
            method: "post",
            data: params,
          })
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: this.dialogTitle + "症状成功！",
                });
                this.getList();
                this.dialogVisible = false;
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  height: 26px;
  margin-bottom: 10px;

  i {
    width: 25px;
    height: 25px;
    background-color: #2632fb;
    color: white;
    line-height: 25px;
    text-align: center;
    margin-right: 8px;
    border-radius: 2px;
  }
}

.gg-container {
  position: relative;
}

.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

/*.title-icon {*/
/*	margin-right: 15px;*/
/*	color: #fff;*/
/*	font-size: 18px;*/
/*	padding: 5px;*/
/*	border-radius: 2px;*/
/*	background: #2362fb;*/
/*}*/

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
